import React from 'react';
import { Link } from 'gatsby';

import Logo from '../assets/logos/eventspace.svg';

const Header = () => (
  <header className="fixed z-10 top-0 inset-x-0 bg-white bg-opacity-60">
    <div className="container flex h-20 justify-between">
      <Link to="/" aria-label="Eventspace" className="flex items-center">
        <Logo className="h-7 text-black" />
      </Link>
      <Link
        to="/kontakt"
        className="flex items-center uppercase text-mint-dark hover:text-black"
      >
        Plane dein Event
      </Link>
    </div>
  </header>
);

export default Header;
